import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import SocialLinks from "../components/sociallinks";
import PortfolioList from "../components/list-portfolio";
import BlogList from "../components/list-blog";
import StyledBackgroundSection from "../components/background-image";
import Helmet from 'react-helmet'
import SectionTitle from "../components/sectiontitle";

// import Contact from "../components/contact";
const ArtIcon = () => <img width="100" alt="icon of art" src={require('../images/painting-100px.png')} />
const PanoramaIcon = () => <img width="100" alt="icon of a panorama" src={require('../images/panorama-100px.png')} />
const CartographyIcon = () => <img width="100" alt="icon of a cartographic artifact" src={require('../images/map-100px.png')} />


class IndexPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            winHeight: "100vh"
        };
    }

    render() {
        return (
            <Layout placeholder={false}>
                <SEO
                    lang="en"
                    title={this.props.data.site.siteMetadata.title}
                />

                <Helmet
                    bodyAttributes={{
                        className: 'homepage'
                    }}
                />
                
                <div
                    style={{ height: this.state.winHeight + "px" }}
                >
                    <div 
                        style={{ height: 140 + "px" }}
                    ><p>&nbsp;</p></div>
                    <StyledBackgroundSection className="intro container">
                        {/* <div className="main-title text-primary">
                            <h1>{this.props.data.site.siteMetadata.title}</h1>
                        </div> */}
                    </StyledBackgroundSection>
                </div>
                
                <div className="subtext">
                    <div className="float-left tag-line-wrapper">
                        <p className="tag-line text-secondary">
                            {this.props.data.site.siteMetadata.description}
                        </p>
                    </div>

                    <div className="button-wrapper">
                        <a href="#portfolio" className="btn">
                            View works
                        </a>
                    </div>
                </div>

                <section id="doing" className="container">
                    <SectionTitle title="What we do" />

                    <div className="tripod-wrapper clear-both">
                        <div className="text-category grid-item1-1">
                            <a href="/art"><ArtIcon /></a>
                            <h6><a href="/art">Art</a></h6>
                            <p>We make digital fine art that explores the concept of a “natural field theory.” 
                            Living things and minerals may radiate energy in other fields that operate in a 
                            different temporal scale, and/or in wavelengths outside human vision. </p><br />
                            <p>Just as infrared 
                            film allows us to see a heat-field view of things, these artworks explore a 
                            vision of the natural world that fuses reflected light with an envisioning of other energy fields. </p>
                        </div>
                        <div className="text-category grid-item1-2">
                            <a href="/cartography"><CartographyIcon /></a>
                            <h6><a href="/cartography">Cartography</a></h6>
                            <p>The Art of Geography distills geospatial information into digestable maps 
                                using well-thought out design and a clear hierarchy. 
                            Maps can help us understand our relationship to place. 
                               </p><br />
                            <p>Some of the Art of Geography's maps of parks, urban areas, and regions 
                            have become the official maps for state and city parks. We also make 
                            poster maps, very large wall maps and guidebook maps. </p>
                        </div>
                        <div className="text-category grid-item1-3">
                            <a href="/photography"><PanoramaIcon /></a>
                            <h6><a href="/photography">360&deg; photography</a></h6>
                            <p>Virtual reality photography allows you to immersive yourself in  
                            a location at a particular moment. Look around, get a sense of what it is like to be there with 
                            Art of Geography panoramas.</p><br /><p>We've shot over five thousand 360 degree scenes and are 
                            selectively publishing scenes from the archive.
                            </p>
                            </div>
                        {/* <div className="text-category-descr grid-item2-1">
                        </div>
                        <div className="text-category-descr grid-item2-2">
                        </div>
                        <div className="text-category-descr gridi-tem2-3">
                        </div> */}
                    </div>
                </section>

                <div className="social-buttons">
                    <SocialLinks />
                </div>

                <BlogList />

                <section id="done" className="container">
                    <SectionTitle title="What we've done" />
                    <PortfolioList />
                </section>

                {/* <Contact /> */}
                <div className="credit-wrapper">
                <h6 className="credit-title">Credits</h6>
                <p className="credit">folded map icon by priyanka;  
                   panorama icon by supalerk laipawat; and 
                   Canvas painting by Ben Davis; all from the <a href="https://thenounproject.com/">Noun Project.</a> &nbsp; </p>
                </div>

            </Layout>
        );
    }
}

export default IndexPage;

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                capitalizeTitleOnHome
                titleImage
                introTag
                description
                social {
                    name
                    url
                    icon
                }
            }
        }
    }
`;

// export const query = graphql`
//   query {
//     file(relativePath: { eq: "panorama-200px.png" }) {
//       childImageSharp {
//         fixed(width: 125, height: 125) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//   }
// `;
